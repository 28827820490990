<template>

    <div class="card card-widget widget-user-2">
        <div class="widget-user-header bg-gradient-primary">
            <div class="widget-user-image">
                <img class="img-circle elevation-2"
                    src="https://cdn.icon-icons.com/icons2/2483/PNG/512/user_icon_149851.png" alt="User Avatar">
                <input type="file" style="display: none;">
            </div>
            <h3 class="profile-username text-center">{{operario.nombre}}</h3>
            <p class="profile-username text-center">{{operario.poblacion_operario}}</p>
        </div>
    </div>
</template>
<script>
export default {
    props: ['id', 'operario'],
    data() {
        return {}
    },
}
</script>